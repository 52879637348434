



import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";

import Datepicker from "vuejs-datepicker";
import moment from "moment";
import saveAs from "save-as";

@Component({ components: { Datepicker } })
export default class DailyReportComponent extends Vue {
  public state: any = {
    to: new Date(),
    from: new Date()
  };
  public selectedReport = {
    reportName: "Download Borrower List",
    id: 1,
    fileName: "BorrowerList"
  };
  public allLoanStatus = [];
  get userType() {
    return this.$store.state.sessionObject.type;
  }
  public getDate(incomingDate) {
    if (incomingDate) {
      let date = incomingDate.getDate();
      let month = incomingDate.getMonth();
      let year = incomingDate.getFullYear();
      return new Date(year, month, date);
    } else return null;
  }

  public async exportReport(selectLoanStatusId) {
    try {
      this.$store.state.wemloLoader = true;
      let selectLoanStatus = "";
      let body = {
        from: new Date(this.state.from),
        to: new Date(this.state.to),
        selectedReport: this.selectedReport,
        selectLoanStatus: selectLoanStatusId
      };
      let response = await Axios.post(
        BASE_API_URL + "common/exportReport",
        body,
        { responseType: "blob" }
      );
      if (response.status == 200 && response.data) {
        saveAs(response.data, this.selectedReport.fileName + `.csv`);
      } else {
        this.$snotify.clear();
        this.$snotify.error("No Data Found");
      }
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
    }
  }

  public async getAllLoanStatus() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "common/getLoanType",
        {
          loanType: "loanstatus"
        });

      this.allLoanStatus = response.data.loanStatus;
    } catch (error) {
      console.log(error);
    }
  }

  created() {
    this.getAllLoanStatus();
  }
}
